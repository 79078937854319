/**
 * Created by Azhar on 17-11-2017.
 */
const DEBUG = true;
const HOST = 'https://dev.edustoke.com';
var DASHBOARD_HOST = 'https://dev.parent.edustoke.com/';
var SCHOOL_DASHBOARD_HOST = 'https://dev.school.edustoke.com/';
var API_BASE_URL = DASHBOARD_HOST + 'api/';
const ENVIRONMENT = "development";
const DOMAIN_NAME = '.edustoke.com';
const RECAPTCHA_SITE_KEY = "6LcMGs8ZAAAAAN1nnH6NRCEXxgkWdOy0uvH5Glwg";
const RECAPTCHA_SECRET_KEY = "6LcMGs8ZAAAAAPDR5qiSm-wTBHD5MDy714nTHziZ";
const GOOGLE_API_KEY = 'AIzaSyDbUSuQ6MivWulRI-HM5BH1q0pSb3Kmxyw';

const grades = [
		{"id": "1", "order": "2", "grade": "Nursery"},
		{"id": "2", "order": "5", "grade": "KG"},
		{"id": "3", "order": "6", "grade": "Class 1"},
		{"id": "4", "order": "7", "grade": "Class 2"},
		{"id": "5", "order": "8", "grade": "Class 3"},
		{"id": "6", "order": "9", "grade": "Class 4"},
		{"id": "7", "order": "10", "grade": "Class 5"},
		{"id": "8", "order": "11", "grade": "Class 6"},
		{"id": "9", "order": "12", "grade": "Class 7"},
		{"id": "10", "order": "13", "grade": "Class 8"},
		{"id": "11", "order": "14", "grade": "Class 9"},
		{"id": "12", "order": "15", "grade": "Class 10"},
		{"id": "13", "order": "16", "grade": "Class 11"},
		{"id": "14", "order": "17", "grade": "Class 12"},
		{"id": "15", "order": "3", "grade": "LKG"},
		{"id": "16", "order": "1", "grade": "Pre-Nursery"},
		{"id": "17", "order": "4", "grade": "UKG"}
];
const boards = [
		{"id": "1", "board": "CBSE"},
		{"id": "2", "board": "ICSE"},
		{"id": "3", "board": "IB"},
		{"id": "4", "board": "Other board"},
		{"id": "5", "board": "State Board"},
		{"id": "6", "board": "IGCSE"},
		{"id": "7", "board": "To be affiliated to CBSE"},
		{"id": "8", "board": "To be affiliated to ISC/ICSE"},
		{"id": "9", "board": "To be affiliated to STATE/OTHER BOARD"},
		{"id": "10", "board": "To be affiliated to INTERNATIONAL BOARD"}
];
const regions = [
		{"id": "1", "name": "north"},
		{"id": "2", "name": "east"},
		{"id": "3", "name": "south"},
		{"id": "4", "name": "west"},
		{"id": "5", "name": "central"}
];
const schooltypes = [
		{"id": "1", "schooltype": "Day School"},
		{"id": "2", "schooltype": "Pre School"},
		{"id": "3", "schooltype": "Boarding School"},
		{"id": "4", "schooltype": "Day cum Boarding"},
		{"id": "5", "schooltype": "PU College/Junior College"},
		{"id": "6", "schooltype": "Online School"}
];

const GENDERS = ['male', 'female', 'others'];
const COED_TYPES = [
		{id: 1, coedtype: "Co-Ed School"},
		{id: 2, coedtype: "Only Girls School"},
		{id: 3, coedtype: "Only Boys School"}
];
const CATEGORIES_PRE_SCHOOLS = [
		{id: 1, text: 'Montessori'},
		{id: 2, text: 'Multiple Intelligence'},
		{id: 3, text: 'Reggio Emilia'},
		{id: 4, text: 'Play way'},
		{id: 5, text: 'Waldorf'},
		{id: 6, text: 'Others'},
		{id: 7, text: 'Proprietary Pedagogy'}
];
const FEE_BRACKETS = [
		{type: "pre", order: "0", id: "<2500", text: "<2.5K"},
		{type: "pre", order: "1", id: ">2500_<5000", text: "2.5K to 5K"},
		{type: "pre", order: "2", id: ">5000_<8000", text: "5k to 8K"},
		{type: "pre", order: "3", id: ">8000", text: ">8K"},
		{type: "day", order: "4", id: "<30000", text: "<30 K"},
		{type: "day", order: "5", id: ">30000_<50000", text: "30K to 50K"},
		{type: "day", order: "6", id: ">50000_<70000", text: "50K to 70K"},
		{type: "day", order: "7", id: ">70000_<100000", text: "70K to 1 Lac"},
		{type: "day", order: "8", id: ">100000_<200000", text: "1 Lac to 2 Lac"},
		{type: "day", order: "9", id: ">200000", text: ">2 Lac"},
		{type: "res", order: "10", id: ">500_<300000", text: "<3 Lac"},
		{type: "res", order: "11", id: ">300000_<500000", text: "3 Lac to 5 Lac"},
		{type: "res", order: "12", id: ">500000_<700000", text: "5 Lac to 7 Lac"},
		{type: "res", order: "13", id: ">700000_<1000000", text: "7 Lac to 10 Lac"},
		{type: "res", order: "14", id: ">1000000", text: ">10 Lac"}
];
const FACILITIES = [
		{type: "", id: "facility-hasac", text: "AC Classrooms"},
		{type: "day", id: "facility-swimming", text: "Swimming Pool"},
		{type: "day", id: "facility-day_boarding", text: "Day Boarding"},
		{type: "pre", id: "facility-transport", text: "Transportation"},
		{type: "pre", id: "facility-playarea", text: "Play Area"},
		{type: "pre", id: "facility-cctv", text: "CCTV camera"},
		{type: "pre", id: "facility-creche", text: "Day Care"}
];
const PG_SCHOOL = [
		{id: 0, filter: 1, text: 'Private'},
		{id: 1, filter: 2, text: 'Government'}
];

const SCHOOL_TYPES = schooltypes.slice();
const GRADES = grades.slice();
const BOARDS = boards.slice();
const REGIONS = regions.slice();

if (typeof toastr !== "undefined") {
		toastr.options = {
				closeButton: true,
				debug: false,
				newestOnTop: true,
				progressBar: true,
				positionClass: "toast-bottom-center",
				preventDuplicates: true,
				onclick: null,
				showDuration: "300",
				hideDuration: "1000",
				timeOut: "5000",
				extendedTimeOut: "1000",
				showEasing: "swing",
				hideEasing: "linear",
				showMethod: "fadeIn",
				hideMethod: "fadeOut"
		}
}

const feeRangesForFilter = {
	1: {
	 feeMin: 500,
	 feeMax: 1000000
	},
	2: {
	 feeMin: 500,
	 feeMax: 250000,
	},
	3: {
	 feeMin: 500,
	 feeMax: 2500000
	},
	5: {
	 feeMin: 500,
	 feeMax: 500000
	}
  }


